.customDropdownWrap{
    cursor: pointer;
    /* margin-left: 10px; */
    flex-wrap: wrap;
    padding: 4px 8px;
    transition: 0.4s ease-in-out;
    position: relative;
}

.createMentorTab1 .customDropdownWrap{
   padding: 0;
}

/* .customDropdownWrap:hover{
    transition: 0.4s ease-in-out;
} */

/* .customDropdownWrap:hover .dropDrop{
    color: var(--blue);
}

.customDropdownWrap:hover svg path{
    fill: #0052CC;
} */

.dropMenu{
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.dropDrop{
    width: max-content;
    gap: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--black3);
}

.dropMenu input{
    display: none;
}

.dropMenuWindow{
    width: 100%;
    position: absolute;
    top: calc(100% + 10px);
    min-width: 240px;
    padding: 8px;
    background: var(--white);
    z-index: 99;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: 0.4s ease-in-out;
}

.customDropdownWrap.active .dropMenuWindow{
    z-index: 9;
    opacity: 1;
    visibility: visible;
    transition: 0.4s ease-in-out;
}

.customDropdownWrap .dropDrop img{
    transition: 0.4s ease-in-out;
}

.customDropdownWrap.active .dropDrop img{
    transform: rotate(180deg);
    transition: 0.4s ease-in-out;
}

.dropMenu{
    width: 100%;
    display: block;
    position: relative;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    padding: 6px 8px;
    background: var(--white);
    transition: 0.3s ease-in-out;
}

.dropMenu:hover{
    background: var(--grey3);
    transition: 0.3s ease-in-out;
}

.customDropdownWrap label{
    display: block;
    width: 100%;
    font-weight: 400;
    font-size: 13px;
    line-height: 120%;
    margin-bottom: 8px;
}
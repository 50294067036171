.multiSelectBlock {
    width: max-content;
    border-radius: 20px;
    border: 1px solid #DDD;
    background: #FFF;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    gap: 10px;
    min-height: 44px;

    .tagFilterWrap {
        display: flex;
        align-items: center;
        gap: 10px;
        border-right: 1px solid #8A8B9B;
        padding-right: 8px;

        svg {
            cursor: pointer;
        }

        svg rect {
            transition: .3s;
        }


        svg:hover rect {
            fill: #101010;
        }

        p {
            color: #5A5A5A;
            font-size: 14px;
            font-weight: 500;
            line-height: 125%;
        }
    }

    .tagFilterWrap .textBlue{
        color: var(--blue);
    }
}

.calenderBlock {
    display: flex;
    align-items: center;
    gap: 18px;
    cursor: pointer;

    span {
        color: #5A5A5A;
        font-size: 14px;
        font-weight: 500;
        line-height: 125%;
    }
}

.parentMultiSelect {
    position: relative;

    .errorTextMessage {
        position: absolute;
        bottom: -20px;
    }
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

.mainWrapper.fullHeight{
  max-height: calc(100vh - 55px);
}

.mainContainer{
  height: 100vh;
}

.mainWrapper{
  background: var(--grey2) ;
  padding: 20px;
  max-height: calc(100vh - 109px);
  overflow: auto;
  height: 100vh;
}

.mainWrapper.singleHeader{
  height: calc(100vh - 55px);
  max-height: 100vh;
}
.css-1u9des2-indicatorSeparator{
 display: none;
}

.css-1xc3v61-indicatorContainer{
  padding: 0 !important;
}

.customSelect__menu{
  min-width: 250px !important;
}

.css-15lsz6c-indicatorContainer{
  padding: 0 !important;
}

.groupTwoBtn{
  display: flex;
  align-items: center;
  gap: 16px;
}
.sideFilterBox {
    position: fixed;
    top: 0;
    right: -100%;
    max-width: 400px;
    width: 100%;
    height: 100vh;
    background: var(--white);
    border-left: 1px solid #DDDDDD;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    transition: 0.4s ease-in-out;
    z-index: 1000;
}

.sideFilterBoxWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    visibility: hidden;
    transition: 0.4s ease-in-out;
}

.sideFilterBoxWrapper::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);

    opacity: 0;
    visibility: hidden;
    z-index: -1;
    transition: 0.4s ease-in-out;
}

.sideFilterBoxWrapper.active .sideFilterBox {
    right: 0;
    transition: 0.4s ease-in-out;
}

.sideFilterBoxWrapper.active::before {
    opacity: 1;
    visibility: visible;
    z-index: 900;
    /* transition: 0.4s ease-in-out; */
}

.sideFilterBoxWrapper.active {
    z-index: 1000;
    visibility: visible;
    /* transition: 0.4s ease-in-out; */
}

.sideFilterBox .popupHeader {
    padding: 20px;
    justify-content: space-between;
}

.sideFilterBox .popupHeading {
    font-size: 18px;
    font-weight: 600;
}

.sideMenuBody {
    /* height: 100%; */
    padding: 20px;
}


.filterDrop {
    margin-left: 0;
}

.filterDrop .dropDrop {
    padding: 12px;
    background: var(--grey3);
    width: 100%;
    padding: 12px;
    border-radius: 6px;
    justify-content: space-between;
}

.filterDrop .dropMenuWindow {
    top: calc(100% - 16px);
    box-shadow: none;
    border-radius: 0px 0px 6px 6px;
    background: var(--grey3);
    padding: 0;
    max-height: 200px;
    overflow: auto;
}

.filterDrop label {
    background: transparent;
    margin-bottom: 5px;
}

.filterDrop .dropMenuWindow label {
    background: transparent;
    padding: 12px;
    margin-bottom: 0;
}

.filterDrop .dropMenuWindow label:hover {
    background: var(--grey1);
}

.sideMenuFooter {
    padding: 10px 20px;
    gap: 10px;
    justify-content: flex-end;
    background: var(--grey4);
    border-top: 1px solid var(--grey2);
}

.sideFilterBox .sideMenuBody {
    height: calc(100% - 130px);
}

.sideFilterBox .button {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 12px;
}

.sideFilterBoxWrapper .customDropdownWrap:hover {
    background: transparent;
}

.sideFilterBoxWrapper .customDropdownWrap:hover .dropDrop {
    color: var(--black3);
}

.sideFilterBoxWrapper .customDropdownWrap:hover svg path {
    fill: var(--black3);
}

.close {
    cursor: pointer;
}

.impRed {
    color: var(--red);
}

.gapColumn16 {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .multiSelectBlockCustom {
        width: 100%;
    }

    .css-b62m3t-container {
        width: 100%;
    }
}
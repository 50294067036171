.dataNotfoundwrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    width: 100%;
    flex-direction: column;
}

.dataNotfoundwrapper .text2 {
    font-size: 14px;
    font-weight: 300;
    max-width: 285px;
    width: 100%;
}

.nodatafound {
    text-align: center;
}

.h3datatext {
    font-weight: 300;
    font-size: 24px;
    line-height: 120%;
    color: var(--black-5);
    margin-bottom: 10px;
}
.inputsearchdsAs {
    border-bottom: 1px solid #F5F5F5;
    padding: 12px 12px 10px 12px;
    width: 100%;
    display: flex;

}

.inputsearchdsAs input {
    outline: none;
    border: none;
    width: 100%;
    max-width: calc(100% - 14px);
    font-weight: 500;
    line-height: 120%;
    color: var(--black-5);
}

.Addsdetailwrap .dsadsearchbar {
    width: 100%;
    border-radius: 6px;
    border: 1px solid #F5F5F5;
    background: #FFF;
}

.Addsdetailwrap .dsadsearchbar .text3 {
    font-size: 13px;
    color: var(--black-5);
    font-weight: 400;
}

.Addsdetailwrap .dropdownselect {
    padding: 12px;
}

.Addsdetailwrap .listwrapfordropdown li {
    border: 1px;

}

.mulipleFormInputWrap .CustomDropdownmain.countryCode {
    width: 40% !important;
}

.CustomDropdownmain {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
}

.ContainerTabForAllTabData .CustomDropdownmain {
    width: max-content;
}

.ContainerTabForAllTabData .filterbodywrap .CustomDropdownmain {
    width: 100%;
}

.ContainerTabForAllTabData .heightfixadd .CustomDropdownmain {
    width: 100%;
}

.dropdownselect {
    cursor: pointer;
    padding: 11px 12px;
    border-radius: 6px;
    background: var(--grey3);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.dropdownselect .text3 {
    font-size: 16px;
}

.ListthatSelected {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    background: var(--white);
    position: absolute;
    top: 60px;
    width: 100%;
    min-width: 200px;
    visibility: hidden;
    opacity: 0;
    transform: translateY(0px);
    transition: 0.4s;
    height: 0;
    z-index: 105;
    overflow: hidden;
}

.CustomDropdownmain.active .ListthatSelected {
    visibility: visible;
    opacity: 1;
    transform: translateY(8px);
    height: auto;
    overflow: unset;
}

.listwrapfordropdown {
    max-height: 190px;
    overflow-y: auto;
}

.listwrapfordropdown li {
    padding: 16px 0 12px 16px;
    border-bottom: 1px solid #F5F5F5;
    cursor: pointer;
    width: 100%;
    overflow: hidden;
    transition: 0.4s;
}

.listwrapfordropdown li:hover {
    background: var(--grey-5);
}

.listwrapfordropdown li p {
    transition: 0.4s;
    width: 100%;
    font-size: 14px;
}

.listwrapfordropdown li:hover p {
    color: var(--blue-7);
    transform: translateX(5px);
}

.listwrapfordropdown li:last-child {
    border-bottom: none;
    padding-bottom: 16px;
}

.CustomDropdownmain .downarrow {
    transition: 0.4s;
}

.CustomDropdownmain.active .downarrow {
    transform: rotate(180deg);
}

.slotdatefix .dropdownselect {
    padding: 9px 12px;
}

.CustomDropdownmain {
    .labelName {
        font-weight: 400;
        font-size: 13px;
        line-height: 120%;
        margin-bottom: 5px;
        display: flex;
    }
}

@media (max-width: 767px) {
    .listwrapfordropdown li p {
        font-size: 12px;
    }
}
.Logoutpopupbody{
    padding: 15px;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Logoutpopupbody .closebtn{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    cursor: pointer;
}


.Logoutpopupbody .text14{
    font-size: 15px;
    font-weight: 700;
    line-height: 120%;
    color: var(--black);
}